import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import Select from "@mui/material/Select";
import MDTypography from "components/MDTypography";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";

import * as React from "react";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

import cookie from "services/cookie.js";

export default function Lankomumas() {
  const [value, setValue] = React.useState(dayjs());
  const [childsData, setChildsData] = React.useState(null);
  const [checked, setChecked] = React.useState(null);
  const childRows = [];
  const token = cookie.getCookie("token");
  const currentUser = cookie.getUser("token");

  const [grupe, setGrupe] = React.useState(
    currentUser.groups == 4 ? 1 : currentUser.groups == 21 ? 5 : 11
  );

  const handleGroupChange = (event) => {
    setGrupe(event.target.value);
  };

  const handleChange = (event, i, id) => {
    const newArray = [...checked];
    newArray[i] = event.target.checked;

    fetch("api/lankomumas", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        data: dayjs(value).format("YYYY-MM-DD"),
        vaikas: id,
        lanke: event.target.checked,
      }),
    })
      .then((res) => res.json())
      .then(() => setChecked(newArray))
      .catch((err) => console.info(err));
  };

  const returnChild = (name, email) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">Tėvai: {email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  function setLankomumas(data) {
    const newChecked = [];

    if (childsData) {
      for (let i = 0; i < childsData.results.length; i += 1) {
        newChecked[i] = !!data.filter(
          (item) => item.vaikas_id === childsData.results[i].id
        )[0];
      }

      setChecked(newChecked);
    }
  }

  React.useEffect(() => {
    fetch(`api/vaikai/${grupe}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    })
      .then((res) => res.json())
      .then((data) => setChildsData(data));
  }, [grupe]);

  React.useEffect(() => {
    fetch(`api/dienos-lankomumas/${dayjs(value).format("YYYY-MM-DD")}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    })
      .then((res) => res.json())
      .then((data) => setLankomumas(data.results));
  }, [value, childsData]);

  for (let i = 0; i < childsData?.results?.length; i += 1) {
    childRows.push({
      vaikas: returnChild(
        childsData.results[i].vaikas,
        childsData.results[i].tevas
      ),
      kontaktai: childsData.results[i].pastas,
      lankomumas: (
        <Checkbox
          checked={(checked && checked[i]) || false}
          onChange={(e) => handleChange(e, i, childsData.results[i].id)}
        />
      ),
    });
  }

  const columns = [
    { Header: "vaikas", accessor: "vaikas", align: "left", width: "20%" },
    { Header: "Atvyko", accessor: "lankomumas", align: "left" },
  ];

  const rows = childRows;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item sm={4}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Grupė</InputLabel>

              {currentUser.groups == 4 ? (
                <Select
                  value={grupe}
                  label="Grupė"
                  onChange={handleGroupChange}
                  sx={{ height: "44px", marginBottom: "16px" }}
                >
                  <MenuItem value={1}>
                    6-8 metų grupė - Trenerė Karolina (19:10 val. Pirmadieniais
                    ir trečiadieniais){" "}
                  </MenuItem>
                  <MenuItem value={2}>
                    6-8 metų grupė - Trenerė Julija (19:00 val. Antradieniais ir
                    ketvirtadieniais)
                  </MenuItem>
                  <MenuItem value={3}>
                    9-14 metų grupė - Trenerė Karolina (18:20 val. Pirmadieniais
                    ir trečiadieniais)
                  </MenuItem>
                  <MenuItem value={4}>
                    4-6 metų grupė - Trenerė JULIJA (17:30 val. Antradieniais ir
                    Ketvirtadieniais)
                  </MenuItem>
                  <MenuItem value={5}>
                    4-6 metų grupė - Trenerė RŪTA (19:00 val. Antradieniais ir
                    Ketvirtadieniais)
                  </MenuItem>
                  <MenuItem value={6}>
                    4-6 metų grupė - Trenerė JULIJA (16:30 val. Pirmadieniais ir
                    Ketvirtadieniais)
                  </MenuItem>
                  <MenuItem value={7}>
                    TĖVAI + VAIKAI - Trenerė JULIJA (17:30 val. Pirmadieniais)
                  </MenuItem>
                </Select>
              ) : currentUser.groups == 21 ? (
                <Select
                  value={grupe}
                  label="Grupė"
                  onChange={handleGroupChange}
                  sx={{ height: "44px", marginBottom: "16px" }}
                >
                  <MenuItem value={5}>
                    4-6 metų grupė - Trenerė RŪTA (19:00 val. Antradieniais ir
                    Ketvirtadieniais)
                  </MenuItem>
                </Select>
              ) : (
                <Select
                  value={grupe}
                  label="Grupė"
                  onChange={handleGroupChange}
                  sx={{ height: "44px", marginBottom: "16px" }}
                >
                  <MenuItem value={11}>
                    5-8 metų grupė - Trenerė Ugnė (18:00 val. Pirmadieniais ir
                    trečiadieniais){" "}
                  </MenuItem>
                  <MenuItem value={12}>
                    8-10 metų grupė - Trenerė Ugnė (19:00 val. Pirmadieniais ir
                    trečiadieniais)
                  </MenuItem>
                  <MenuItem value={13}>
                    5-8 metų grupė - Trenerė Ugnė (18:00 val. Antradienis ir
                    Ketvirtadieniais){" "}
                  </MenuItem>
                </Select>
              )}
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={3}>
                <MobileDatePicker
                  label="Žymima diena"
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                mb={-1}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                {currentUser.groups == 4 ? (
                  <MDTypography variant="h6" color="white">
                    {grupe === 1
                      ? "6-8 metų grupė - Trenerė Karolina (19:10 val. Pirmadieniais ir trečiadieniais)"
                      : grupe === 2
                      ? "6-8 metų grupė - Trenerė Julija (19:00 val. Antradieniais ir ketvirtadieniais)"
                      : grupe === 4
                      ? "4-6 metų grupė - Trenerė JULIJA (17:30 val. Antradieniais ir Ketvirtadieniais)"
                      : grupe === 6
                      ? "4-6 metų grupė - Trenerė JULIJA (16:30 val. Pirmadieniais ir Ketvirtadieniais)"
                      : grupe === 7
                      ? "TĖVAI + VAIKAI - Trenerė JULIJA (17:30 val. Pirmadieniais)"
                      : grupe === 5
                      ? "4-6 metų grupė - Trenerė RŪTA (19:00 val. Antradieniais ir Ketvirtadieniais)"
                      : "9-14 metų grupė - Trenerė Karolina (18:20 val. Pirmadieniais ir trečiadieniais)"}
                  </MDTypography>
                ) : (
                  <MDTypography variant="h6" color="white">
                    {grupe === 11
                      ? "5-8 metų grupė - Trenerė Ugnė (18:00 val. Pirmadieniais ir trečiadieniais)"
                      : grupe === 5
                      ? "4-6 metų grupė - Trenerė RŪTA (19:00 val. Antradieniais ir Ketvirtadieniais)"
                      : grupe === 12
                      ? "8-10 metų grupė - Trenerė Ugnė (19:00 val. Pirmadieniais ir trečiadieniais)"
                      : "5-8 metų grupė - Trenerė Ugnė (18:00 val. Antradienis ir Ketvirtadieniais) "}
                  </MDTypography>
                )}
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDButton from "components/MDButton";
import dayjs from "dayjs";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import TextField from "@mui/material/TextField";
import MDTypography from "components/MDTypography";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import cookie from "services/cookie.js";

class NameForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vardas: "",
      pastas: "",
      numeris: "",
      grupe: "",
      treneris: 1,
      kaina: 15,
      galioja_iki: dayjs(new Date()).add(30, "day").format("YYYY-MM-DD"),
      disabled: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleDateChange(value) {
    this.setState({ galioja_iki: dayjs(value).format("YYYY-MM-DD") });
  }

  handleSubmit(event) {
    const token = cookie.getCookie("token");
    const currentUser = cookie.getUser("token");
    let path = "api/naujas-narys";

    if (currentUser.level == 9) {
      path = "api/naujas-narys-admin";
    }

    this.setState({ disabled: true });

    fetch(path, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(this.state),
    })
      .then((res) => res.json())
      .then(() => location.reload())
      .then(() => alert("Naujas narys įtrauktas"))
      .catch((err) => console.info(err));

    event.preventDefault();
  }

  componentDidMount() {
    const token = cookie.getCookie("token");

    fetch(`api/nariai/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    })
      .then((res) => res.json())
      .then((data) =>
        this.setState({ nariai: data.results, grupe: data.results[0].grupe })
      );
  }

  render() {
    const { nariai } = this.state;
    const currentUser = cookie.getUser("token");
    const galimosGrupes = [];
    const galimiTreneriai = [];

    for (let i = 0; i < nariai?.length; i++) {
      if (!galimosGrupes.includes(nariai[i].grupe))
        galimosGrupes.push(nariai[i].grupe);

      if (!galimiTreneriai.includes(nariai[i].treneris))
        galimiTreneriai.push(nariai[i].treneris);
    }

    console.info("galimosGrupes", galimosGrupes, galimiTreneriai, nariai);

    return (
      <DashboardLayout>
        <DashboardNavbar absolute isMini />
        <MDBox>
          <MDBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={4}>
                <Card>
                  <MDBox p={3}>
                    <form onSubmit={this.handleSubmit}>
                      <Grid item xs={12} lg={12} mb={2}>
                        <MDTypography
                          ariant="button"
                          fontWeight="regular"
                          variant="h5"
                        >
                          Naujas klientas:
                          <br />
                          Grupinės treniruotės
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} lg={12} mb={2}>
                        <TextField
                          required
                          id="outlined-required"
                          label="Vardas ir Pavardė"
                          name="vardas"
                          onChange={this.handleChange}
                          sx={{
                            height: "44px",
                            width: "100%",
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} lg={12} mb={2}>
                        <TextField
                          required
                          id="outlined-required"
                          label="El. paštas"
                          name="pastas"
                          onChange={this.handleChange}
                          sx={{
                            height: "44px",
                            width: "100%",
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} lg={12} mb={2}>
                        <TextField
                          required
                          id="outlined-required"
                          label="Tel. numeris"
                          name="numeris"
                          onChange={this.handleChange}
                          sx={{
                            height: "44px",
                            width: "100%",
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} lg={12} mb={2}>
                        <Select
                          id="outlined-select-currency"
                          label="Kaina"
                          name="kaina"
                          sx={{
                            height: "44px",
                            width: "100%",
                          }}
                          value={this.state.kaina}
                          onChange={this.handleChange}
                        >
                          <MenuItem value="15">
                            15 eur (Individualus / MINI grupė)
                          </MenuItem>
                          <MenuItem value="55">
                            55 eur (Grupinės treniruotės)
                          </MenuItem>
                        </Select>
                      </Grid>
                      {galimosGrupes[0] ? (
                        <Grid item xs={12} lg={12} mb={2}>
                          <Select
                            id="outlined-select-currency"
                            label="Grupė"
                            name="grupe"
                            sx={{
                              height: "44px",
                              width: "100%",
                            }}
                            value={this.state.grupe}
                            onChange={this.handleChange}
                          >
                            {galimosGrupes?.map(function (item, i) {
                              return (
                                <MenuItem key={i} value={item}>
                                  {item}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </Grid>
                      ) : null}

                      {currentUser.level == 9 && galimiTreneriai[0] ? (
                        <>
                          <Grid item xs={12} lg={12} mb={2}>
                            <Select
                              id="outlined-select-currency"
                              label="Treneris"
                              name="treneris"
                              sx={{
                                height: "44px",
                                width: "100%",
                              }}
                              value={this.state.treneris}
                              onChange={this.handleChange}
                            >
                              {galimiTreneriai?.map(function (item, i) {
                                return (
                                  <MenuItem key={i} value={item}>
                                    {item == 1
                                      ? "Gabrielius"
                                      : item == 2
                                      ? "Domantas"
                                      : item == 3
                                      ? "Edgaras"
                                      : item == 4
                                      ? "Karolina"
                                      : item == 5
                                      ? "Titas"
                                      : item == 6
                                      ? "Modesta"
                                      : item == 7
                                      ? "Remigijus"
                                      : "Nežinomas"}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </Grid>
                          <Grid item xs={12} lg={12} mb={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                label="Galioja iki"
                                value={this.state.galioja_iki}
                                onChange={(newValue) => {
                                  this.handleDateChange(newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                            </LocalizationProvider>
                          </Grid>
                        </>
                      ) : null}
                      <MDButton
                        color="success"
                        variant="gradient"
                        type="submit"
                        disabled={this.state.disabled}
                      >
                        Registruoti
                      </MDButton>
                    </form>
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      </DashboardLayout>
    );
  }
}

export default NameForm;

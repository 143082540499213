import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import MDTypography from "components/MDTypography";
import Avatar from "@mui/material/Avatar";
import dayjs from "dayjs";
import { green, grey, red, blue } from "@mui/material/colors";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";

import * as React from "react";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";

import cookie from "services/cookie.js";

export default function Suvestine() {
  const [nariai, setNariai] = React.useState([]);
  const [grupe, setGrupe] = React.useState(0);
  const token = cookie.getCookie("token");
  const galimosGrupes = [];

  const handleGroupChange = (event) => {
    setGrupe(event.target.value);
  };

  React.useEffect(() => {
    fetch(`api/nariai/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    })
      .then((res) => res.json())
      .then((data) => setNariai(data.results));
  }, [grupe]);

  for (let i = 0; i < nariai?.length; i++) {
    if (!galimosGrupes.includes(nariai[i].grupe))
      galimosGrupes.push(nariai[i].grupe);
  }
  const columns = [
    { Header: "", accessor: "status", align: "left", width: "10px" },
    { Header: "Apmokėta", accessor: "apmoketa", align: "center" },
    { Header: "Asmuo", accessor: "asmuo", align: "left" },
    { Header: "Moka", accessor: "kaina", align: "left" },
    { Header: "Galioja iki", accessor: "galioja_iki", align: "left" },
    { Header: "Grupė", accessor: "grupe", align: "left" },
    { Header: "Statusas", accessor: "veiksmai", align: "left" },
    { Header: "Komentaras", accessor: "komentaras", align: "left" },
  ];

  function sendNotificationStabdytiAtostogas(data) {
    const id = data.target.id;

    fetch("api/stabdyti-atostogas", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        id: id,
      }),
    })
      .then((res) => res.json())
      .then((data) => alert(data.message))
      .catch((err) => console.info(err));

    console.info("event");
  }

  let rows = nariai ? JSON.parse(JSON.stringify(nariai)) : [];

  rows = rows.filter(function (el) {
    return el.grupe == galimosGrupes[grupe];
  });

  for (let i = 0; i < rows.length; i++) {
    rows[i].asmuo = (
      <MDBox display="flex" alignItems="center" lineHeight={1}>
        <MDBox lineHeight={1}>
          <MDTypography display="block" variant="button" fontWeight="medium">
            {rows[i].asmuo}
          </MDTypography>
          <MDTypography variant="caption">{rows[i].pastas}</MDTypography>
          {rows[i].numeris ? (
            <>
              <br />
              <MDTypography variant="caption">{rows[i].numeris}</MDTypography>
            </>
          ) : null}
        </MDBox>
      </MDBox>
    );
    rows[i].komentaras = (
      <TextField
        placeholder={"Ka pakeisti / Ka reiktų žinoti"}
        multiline
        rows={2}
        value={rows[i].komentaras || ""}
        name={String(rows[i].id)}
        onChange={(e) => onCommentChange(e)}
      />
    );

    rows[i].veiksmai = (
      <>
        <div>
          <input
            type="radio"
            id={`sport_${String(rows[i].id)}`}
            name={String(rows[i].id)}
            value="1"
            checked={rows[i].veiksmai == 1}
            onChange={(e) => onStatusChange(e)}
          />
          <label htmlFor={`sport_${String(rows[i].id)}`}>Sportuoja</label>
        </div>

        <div>
          <input
            type="radio"
            id={`nebesportuoja_${String(rows[i].id)}`}
            name={String(rows[i].id)}
            value="2"
            checked={rows[i].veiksmai == 2}
            onChange={(e) => onStatusChange(e)}
          />
          <label htmlFor={`nebesportuoja_${String(rows[i].id)}`}>
            Nebesportuoja visam
          </label>
        </div>

        <div>
          <input
            type="radio"
            id={`serga_${String(rows[i].id)}`}
            name={String(rows[i].id)}
            value="6"
            checked={rows[i].veiksmai == 6}
            onChange={(e) => onStatusChange(e)}
          />
          <label htmlFor={`serga_${String(rows[i].id)}`}>
            Atostogauja / serga
          </label>
        </div>

        <div>
          <input
            type="radio"
            id={`nezinau_${String(rows[i].id)}`}
            name={String(rows[i].id)}
            value="3"
            checked={rows[i].veiksmai == 3}
            onChange={(e) => onStatusChange(e)}
          />
          <label htmlFor={`nezinau_${String(rows[i].id)}`}>Nežinau</label>
        </div>

        <div>
          <input
            type="radio"
            id={`neta_${String(rows[i].id)}`}
            name={String(rows[i].id)}
            value="4"
            checked={rows[i].veiksmai == 4}
            onChange={(e) => onStatusChange(e)}
          />
          <label htmlFor={`neta_${String(rows[i].id)}`}>Ne toje grupėje</label>
        </div>
      </>
    );

    rows[i].kaina = (
      <>
        {rows[i].kaina} EUR
        <br />
        Kas {rows[i].mok_laikotarpis} mėn.
      </>
    );

    if (rows[i].sustabdziusi) {
      rows[i].apmoketa = (
        <>
          Atostogos <br />
          <Button
            variant="text"
            id={rows[i].id}
            onClick={(e) => sendNotificationStabdytiAtostogas(e)}
          >
            Atvyko į treniruotę
          </Button>
        </>
      );
      rows[i].status = (
        <>
          <Avatar
            sx={{ bgcolor: grey[500], width: 24, height: 24, float: "left" }}
          >
            S
          </Avatar>
          {rows[i].mok_treneriui ? (
            <Avatar
              sx={{
                bgcolor: blue[500],
                width: 24,
                height: 24,
                fontSize: "10px",
              }}
            >
              MT
            </Avatar>
          ) : null}
        </>
      );
    } else if (dayjs(rows[i].galioja_iki) >= dayjs()) {
      rows[i].apmoketa = "Apmokėta";
      rows[i].status = (
        <>
          <Avatar
            sx={{ bgcolor: green[500], width: 24, height: 24, float: "left" }}
          >
            A
          </Avatar>
          {rows[i].mok_treneriui ? (
            <Avatar
              sx={{
                bgcolor: blue[500],
                width: 24,
                height: 24,
                fontSize: "10px",
              }}
            >
              MT
            </Avatar>
          ) : null}
        </>
      );
    } else {
      rows[i].apmoketa = `Neapmokėta ${dayjs(rows[i].galioja_iki).diff(
        new Date(),
        "days"
      )} dienų`;
      rows[i].status = (
        <>
          <Avatar
            sx={{ bgcolor: red[500], width: 24, height: 24, float: "left" }}
          >
            N
          </Avatar>
          {rows[i].mok_treneriui ? (
            <Avatar
              sx={{
                bgcolor: blue[500],
                width: 24,
                height: 24,
                fontSize: "10px",
              }}
            >
              MT
            </Avatar>
          ) : null}
        </>
      );
    }

    if (rows[i].statusas == 0) {
      rows[i].apmoketa = "0 - Nepatvirtintas Evaldo";
    }
  }

  function compare(a, b) {
    if (a.apmoketa < b.apmoketa) {
      return -1;
    }
    if (a.apmoketa > b.apmoketa) {
      return 1;
    }
    return 0;
  }

  rows.sort(compare);

  function onCommentChange(event) {
    const { target } = event;
    const { value, name } = target;

    const newNariai = [...nariai];

    for (let i = 0; i < newNariai?.length; i++) {
      if (newNariai[i].id == name) {
        newNariai[i].komentaras = value;
      }
    }

    fetch("api/komentaras", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        data: dayjs(value).format("YYYY-MM-DD"),
        id: name,
        komentaras: value,
      }),
    })
      .then((res) => res.json())
      .then(() => setNariai(newNariai))
      .catch((err) => console.info(err));
  }

  function onStatusChange(event) {
    const { target } = event;
    const { value, name } = target;

    const newNariai = [...nariai];

    for (let i = 0; i < newNariai?.length; i++) {
      if (newNariai[i].id == name) {
        newNariai[i].veiksmai = value;
      }
    }

    fetch("api/veiksmai", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        data: dayjs(value).format("YYYY-MM-DD"),
        id: name,
        veiksmai: value,
      }),
    })
      .then((res) => res.json())
      .then(() => setNariai(newNariai))
      .catch((err) => console.info(err));
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item sm={4}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Grupė</InputLabel>
              <Select
                value={grupe}
                label="Grupė"
                onChange={handleGroupChange}
                sx={{ height: "44px", marginBottom: "16px" }}
              >
                {galimosGrupes.map(function (item, i) {
                  return (
                    <MenuItem key={i} value={i}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                mb={-1}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  {galimosGrupes[grupe]}
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  canSearch
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

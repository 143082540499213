import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import Select from "@mui/material/Select";
import MDTypography from "components/MDTypography";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";

import * as React from "react";
import dayjs from "dayjs";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MDButton from "components/MDButton";

import cookie from "services/cookie.js";

export default function Suvestine() {
  const [value, setValue] = React.useState(dayjs().format("MM"));
  const [childsData, setChildsData] = React.useState(null);
  const [lankomumas, setLankomumas] = React.useState(null);
  const childRows = [];
  const token = cookie.getCookie("token");
  const currentUser = cookie.getUser("token");

  const [grupe, setGrupe] = React.useState(
    currentUser.groups == 4 ? 1 : currentUser.groups == 21 ? 5 : 11
  );

  const handleGroupChange = (event) => {
    setGrupe(event.target.value);
  };

  const returnChild = (name, email, skola, diena, gim_data, age) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">Tėvai: {email}</MDTypography>
        <br />
        <MDTypography variant="caption">Skola: {skola} EUR</MDTypography>
        {/* <br />
        <MDTypography variant="caption">Mok. diena: {diena} d.</MDTypography> */}
        <br />
        <MDTypography variant="caption">
          Gim. data: {gim_data ? gim_data : "Nenurodė"}
        </MDTypography>
        <br />
        <MDTypography variant="caption">Amžius: {age}</MDTypography>
      </MDBox>
    </MDBox>
  );

  function getAllDaysInMonth(year, month) {
    const date = new Date(year, month, 1);

    const dates = [];

    while (date.getMonth() === month) {
      dates.push(dayjs(date).format("DD"));
      date.setDate(date.getDate() + 1);
    }

    return dates;
  }

  React.useEffect(() => {
    fetch(`api/vaikai/${grupe}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    })
      .then((res) => res.json())
      .then((data) => setChildsData(data));
  }, [grupe]);

  React.useEffect(() => {
    fetch(`api/lankomumas/${value}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    })
      .then((res) => res.json())
      .then((data) => setLankomumas(data.results));
  }, [value]);

  const monthDays = getAllDaysInMonth(2023, Number(value) - 1);

  for (let i = 0; i < childsData?.results?.length; i += 1) {
    let age = "Nenurodė";

    if (childsData.results[i].gim_data) {
      let dob = new Date(childsData.results[i].gim_data);

      //calculate month difference from current date in time
      let month_diff = Date.now() - dob.getTime();

      //convert the calculated difference in date format
      let age_dt = new Date(month_diff);

      //extract year from date
      let year = age_dt.getUTCFullYear();

      //now calculate the age of the user
      age = Math.abs(year - 1970);
    }

    const childData = {
      vaikas: returnChild(
        childsData.results[i].vaikas,
        childsData.results[i].tevas,
        childsData.results[i].liko_sumoketi,
        childsData.results[i].mokejimo_diena,
        childsData.results[i].gim_data,
        age
      ),
      kontaktai: childsData.results[i].pastas,
    };

    for (let j = 0; j < monthDays.length; j += 1) {
      const vaikoLankomumas = lankomumas
        ? lankomumas.filter(
            (person) =>
              person.vaikas_id === childsData.results[i].id &&
              dayjs(person.data).format("DD") === monthDays[j]
          )
        : [];

      childData[monthDays[j]] = vaikoLankomumas[0] ? (
        <MDButton
          variant="outlined"
          color="success"
          iconOnly
          circular
          size="small"
        >
          <Icon sx={{ fontWeight: "bold" }}>done_all</Icon>
        </MDButton>
      ) : null;
    }

    childRows.push(childData);
  }

  const columns = [{ Header: "vaikas", accessor: "vaikas", align: "left" }];

  for (let i = 0; i < monthDays.length; i += 1) {
    columns.push({
      Header: monthDays[i],
      accessor: monthDays[i],
      align: "left",
    });
  }

  const rows = childRows;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item sm={4}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Grupė</InputLabel>
              {currentUser.groups == 9 ? (
                <Select
                  value={grupe}
                  label="Grupė"
                  onChange={handleGroupChange}
                  sx={{ height: "44px", marginBottom: "16px" }}
                >
                  <MenuItem value={1}>
                    6-8 metų grupė - Trenerė Karolina (19:10 val. Pirmadieniais
                    ir trečiadieniais){" "}
                  </MenuItem>
                  <MenuItem value={2}>
                    6-8 metų grupė - Trenerė Julija (19:00 val. Antradieniais ir
                    ketvirtadieniais)
                  </MenuItem>
                  <MenuItem value={3}>
                    9-14 metų grupė - Trenerė Karolina (18:20 val. Pirmadieniais
                    ir trečiadieniais)
                  </MenuItem>
                  <MenuItem value={4}>
                    4-6 metų grupė - Trenerė JULIJA (17:30 val. Antradieniais ir
                    Ketvirtadieniais)
                  </MenuItem>
                  <MenuItem value={5}>
                    4-6 metų grupė - Trenerė RŪTA (19:00 val. Antradieniais ir
                    Ketvirtadieniais)
                  </MenuItem>
                  <MenuItem value={6}>
                    4-6 metų grupė - Trenerė JULIJA (16:30 val. Pirmadieniais ir
                    Ketvirtadieniais)
                  </MenuItem>
                  <MenuItem value={7}>
                    TĖVAI + VAIKAI - Trenerė JULIJA (17:30 val. Pirmadieniais)
                  </MenuItem>
                  <MenuItem value={11}>
                    5-8 metų grupė - Trenerė Ugnė (18:00 val. Pirmadieniais ir
                    trečiadieniais){" "}
                  </MenuItem>
                  <MenuItem value={12}>
                    8-10 metų grupė - Trenerė Ugnė (19:00 val. Pirmadieniais ir
                    trečiadieniais)
                  </MenuItem>
                  <MenuItem value={13}>
                    5-8 metų grupė - Trenerė Ugnė (18:00 val. Antradienis ir
                    Ketvirtadieniais){" "}
                  </MenuItem>
                </Select>
              ) : currentUser.groups == 4 ? (
                <Select
                  value={grupe}
                  label="Grupė"
                  onChange={handleGroupChange}
                  sx={{ height: "44px", marginBottom: "16px" }}
                >
                  <MenuItem value={1}>
                    6-8 metų grupė - Trenerė Karolina (19:10 val. Pirmadieniais
                    ir trečiadieniais){" "}
                  </MenuItem>
                  <MenuItem value={2}>
                    6-8 metų grupė - Trenerė Julija (19:00 val. Antradieniais ir
                    ketvirtadieniais)
                  </MenuItem>
                  <MenuItem value={3}>
                    9-14 metų grupė - Trenerė Karolina (18:20 val. Pirmadieniais
                    ir trečiadieniais)
                  </MenuItem>
                  <MenuItem value={4}>
                    4-6 metų grupė - Trenerė JULIJA (17:30 val. Antradieniais ir
                    Ketvirtadieniais)
                  </MenuItem>
                  <MenuItem value={5}>
                    4-6 metų grupė - Trenerė RŪTA (19:00 val. Antradieniais ir
                    Ketvirtadieniais)
                  </MenuItem>
                  <MenuItem value={6}>
                    4-6 metų grupė - Trenerė JULIJA (16:30 val. Pirmadieniais ir
                    Ketvirtadieniais)
                  </MenuItem>
                  <MenuItem value={7}>
                    TĖVAI + VAIKAI - Trenerė JULIJA (17:30 val. Pirmadieniais)
                  </MenuItem>
                </Select>
              ) : currentUser.groups == 21 ? (
                <Select
                  value={grupe}
                  label="Grupė"
                  onChange={handleGroupChange}
                  sx={{ height: "44px", marginBottom: "16px" }}
                >
                  <MenuItem value={5}>
                    4-6 metų grupė - Trenerė RŪTA (19:00 val. Antradieniais ir
                    Ketvirtadieniais)
                  </MenuItem>
                </Select>
              ) : (
                <Select
                  value={grupe}
                  label="Grupė"
                  onChange={handleGroupChange}
                  sx={{ height: "44px", marginBottom: "16px" }}
                >
                  <MenuItem value={11}>
                    5-8 metų grupė - Trenerė Ugnė (18:00 val. Pirmadieniais ir
                    trečiadieniais){" "}
                  </MenuItem>
                  <MenuItem value={12}>
                    8-10 metų grupė - Trenerė Ugnė (19:00 val. Pirmadieniais ir
                    trečiadieniais)
                  </MenuItem>
                  <MenuItem value={13}>
                    5-8 metų grupė - Trenerė Ugnė (18:00 val. Antradienis ir
                    Ketvirtadieniais){" "}
                  </MenuItem>
                </Select>
              )}
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Mėnuo</InputLabel>
              <Select
                value={value}
                label="Mėnuo"
                onChange={(newValue) => {
                  setValue(newValue.target.value);
                }}
                sx={{ height: "44px", marginBottom: "16px" }}
              >
                {/* 
              
              <MenuItem value="03">Kovas</MenuItem>
              <MenuItem value="04">Balandis</MenuItem>
              <MenuItem value="05">Gegužė</MenuItem>
              <MenuItem value="06">Birželis</MenuItem>
              <MenuItem value="07">Liepa</MenuItem>
              <MenuItem value="08">Rugpjūtis</MenuItem>
            */}

                <MenuItem value="09">Rugsėjis</MenuItem>
                <MenuItem value="10">Spalis</MenuItem>
                <MenuItem value="11">Lapkritis</MenuItem>
                <MenuItem value="12">Gruodis</MenuItem>
                <MenuItem value="01">Sausis</MenuItem>
                <MenuItem value="02">Vasaris</MenuItem>
                <MenuItem value="03">Kovas</MenuItem>
                <MenuItem value="04">Balandis</MenuItem>
                <MenuItem value="05">Gegužė</MenuItem>
                <MenuItem value="06">Birželis</MenuItem>
                <MenuItem value="07">Liepa</MenuItem>
                <MenuItem value="08">Rugpjūtis</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                mb={-1}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  {grupe === 1
                    ? "6-8 metų grupė - Trenerė Karolina (19:10 val. Pirmadieniais ir trečiadieniais)"
                    : grupe === 2
                    ? "6-8 metų grupė - Trenerė Julija (19:00 val. Antradieniais ir ketvirtadieniais)"
                    : grupe === 3
                    ? "9-14 metų grupė - Trenerė Karolina (18:20 val. Pirmadieniais ir trečiadieniais)"
                    : grupe === 4
                    ? "4-6 metų grupė - Trenerė JULIJA (17:30 val. Antradieniais ir Ketvirtadieniais)"
                    : grupe === 5
                    ? "4-6 metų grupė - Trenerė RŪTA (19:00 val. Antradieniais ir Ketvirtadieniais)"
                    : grupe === 6
                    ? "4-6 metų grupė - Trenerė JULIJA (16:30 val. Pirmadieniais ir Ketvirtadieniais)"
                    : grupe === 7
                    ? "TĖVAI + VAIKAI - Trenerė JULIJA (17:30 val. Pirmadieniais)"
                    : grupe === 13
                    ? "5-8 metų grupė - Trenerė Ugnė (18:00 val. Antradienis ir Ketvirtadieniais)"
                    : grupe === 11
                    ? "5-8 metų grupė - Trenerė Ugnė (18:00 val. Pirmadieniais ir trečiadieniais)"
                    : "8-10 metų grupė - Trenerė Ugnė (19:00 val. Pirmadieniais ir trečiadieniais)"}
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

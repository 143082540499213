import React from "react";
import SignIn from "layouts/authentication/sign-in";
import cookie from "services/cookie.js";

function SecurityComp(props) {
  const [auth, setData] = React.useState(false);
  const token = cookie.getCookie("token");

  const setUserData = function (data) {
    cookie.setUser(data.user);
    setData(data.auth);
  };

  React.useEffect(() => {
    fetch("/api/auth", {
      method: "GET",
      headers: {
        "x-access-token": token,
      },
    })
      .then((res) => res.json())
      .then((data) => setUserData(data));
  }, []);

  if (token && auth) {
    return props.children;
  }

  return <SignIn />;
}

export default SecurityComp;

import * as React from "react";

import Card from "@mui/material/Card";
import Alert from "@mui/material/Alert";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.png";

function Basic() {
  const [email, setEmail] = React.useState(null);
  const [kodas, setKodas] = React.useState(null);
  const [alert, setAlert] = React.useState(null);

  function setCookie(result) {
    if (!result.token) return;
    const cvalue = result.token;
    const cname = "token";
    const exdays = 1;

    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${cname}=${cvalue};${expires};path=/`;
    window.location.reload();
  }

  const handleValidSubmit = (event, values) => {
    setAlert(null);

    if (!email) {
      setAlert("Nenurodytas nario el. pašto adresas");
      return;
    }
    fetch("api/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        kodas,
      }),
    })
      .then((res) => res.json())
      .then((result) => setCookie(result, values))
      .catch(
        setAlert(
          "Narys registruotas tokiu el. paštu nerastas. Prašome susisiektu su mumis el. paštu hi@balticpower.co.uk"
        )
      );
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="El. paštas"
                fullWidth
                onChange={(event) => setEmail(event.target.value)}
              />
            </MDBox>
            {email == "treneris" ? (
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="Kliento kodas"
                  fullWidth
                  onChange={(event) => setKodas(event.target.value)}
                />
              </MDBox>
            ) : null}
            <MDBox mt={2} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={handleValidSubmit}
              >
                Prisijungti
              </MDButton>
            </MDBox>

            {alert ? (
              <Alert variant="outlined" severity="info">
                {alert}
              </Alert>
            ) : null}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;

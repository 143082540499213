/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Alert from "@mui/material/Alert";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import cookie from "services/cookie.js";
import dayjs from "dayjs";

// Overview page components
import Header from "layouts/narioZona/components/Header";
import Payment from "layouts/narioZona/components/Payment";

function Overview() {
  const token = cookie.getCookie("token");
  const [narys, setNarys] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  let showAdditionalMessage = false;

  React.useEffect(() => {
    fetch(`api/narys/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.results[0] && data.results[0].pirmas_prisijungimas) {
          setOpen(false);
        }
        setNarys(data.results);
      });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox mt={2} mb={3}>
          <Grid container spacing={1}>
            {!narys[0] ? (
              <div className="mt-3">
                <Alert severity="success">
                  Aktyvios narystės suaugusiųjų treniruočių grupėje neturite.
                </Alert>
              </div>
            ) : null}
            {narys?.map(function (item, i) {
              let statusas = (
                <Chip
                  color="info"
                  label="Narystė galiojanti"
                  variant="filled"
                  size="small"
                />
              );

              if (dayjs(item.galioja_iki) < dayjs()) {
                //const veluoja = dayjs() - dayjs(item.galioja_iki);
                statusas = (
                  <Chip
                    color="error"
                    label="Neapmokėta narystė"
                    variant="filled"
                    size="small"
                  />
                );
              }

              if (item.sustabdziusi) {
                showAdditionalMessage = true;

                statusas = (
                  <>
                    <Chip
                      color="warning"
                      label="Atostogos: šiuo metu nesportuoja***"
                      variant="filled"
                      size="small"
                    />
                  </>
                );
              }

              if (!item.aktyvus) {
                statusas = (
                  <>
                    <Alert severity="warning">
                      Sporto studijos narystė sustabdyta
                    </Alert>
                  </>
                );
                return (
                  <Grid
                    item
                    xs={12}
                    md={12}
                    xl={12}
                    sx={{ display: "flex" }}
                    key={i}
                  >
                    <ProfileInfoCard
                      info={{
                        vardasPavardė: item.asmuo,
                        Statusas: statusas,
                        Info: (
                          <>
                            Norėdami iš naujo aktyvuoti narystę, turite iš naujo
                            užsiregistruoti prie norimos sportuoti grupės per
                            mūsų internetinę svetainę{" "}
                            <a href="https://www.balticpower.co.uk/">
                              www.balticpower.co.uk
                            </a>
                          </>
                        ),
                      }}
                      notActive={true}
                      shadow={false}
                    />
                  </Grid>
                );
              }

              return (
                <Grid
                  item
                  xs={12}
                  md={6}
                  xl={4}
                  sx={{ display: "flex" }}
                  key={i}
                >
                  <ProfileInfoCard
                    info={{
                      vardasPavardė: item.asmuo,
                      Statusas: statusas,
                      "El. paštas": item.pastas,
                      "Grupė*": item.grupe,
                      "Treniruočių kaina": `${item.kaina} EUR / ${item.mok_laikotarpis} mėn.`,
                      "Narystė galioja iki": `${item.galioja_iki}`,
                      Apmokėti: <Payment user={item} />,
                    }}
                    shadow={false}
                  />
                </Grid>
              );
            })}
          </Grid>
        </MDBox>
        <MDBox mt={0} mb={3}>
          <MDTypography
            ariant="button"
            fontWeight="regular"
            variant="h6"
            color="text"
          >
            <small>
              *Jei priskirta bloga grupė - sportuojate su kita grupe. Prašome
              informuoti mus el. paštu{" "}
              <a href="hi@balticpower.co.uk">hi@balticpower.co.uk</a>
            </small>
          </MDTypography>
          <MDTypography
            ariant="button"
            fontWeight="regular"
            variant="h6"
            color="text"
          >
            <small>
              **Mokėjimo įskaitymas į sistemą gali užtrukti iki 15minučių
              (priklausomai nuo pasirinkto apmokėjimo metodo)
            </small>
          </MDTypography>
          {showAdditionalMessage ? (
            <MDTypography
              ariant="button"
              fontWeight="regular"
              variant="h6"
              color="text"
            >
              <small>
                ***
                <b>
                  Atostogos - tai reiškia, kad šiuo metu nesportuojate, bet
                  planuojate artimu metu grįžti į treniruotes. Laikome
                  užrezervavę vietą grupėje jums. Už šį laikotarpį mokėjimas
                  nesiskaičiuos.
                </b>
                <br />
                Jei esate susistabdę treniruočių lankymą ir planuojate grįžti į
                treniruotes - prašome pranešti mums el. paštu{" "}
                <a href="mailto:hi@balticpower.co.uk">
                  hi@balticpower.co.uk
                </a>{" "}
                arba atvykus į treniruotę treneriui. Abonemento galiojimo laikas
                pradės vėl skaičiuotis nuo pirmos treniruotės po atostogų. Jei
                artimu metu nepalnuojate grįžti į treniruotes - prašome taip pat
                pranešti.
              </small>
            </MDTypography>
          ) : null}
        </MDBox>
      </Header>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box>
      </Modal>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import Alert from "@mui/material/Alert";

function Payment({ user }) {
  function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
  }

  return (
    <MDBox pt={1} pr={2}>
      <form
        action="https://balticpower.co.uk/parduotuve/sistema-vaikai/paymentMethod.php"
        method="post"
      >
        <input
          type="hidden"
          name="p_email"
          id="exampleInputEmail1"
          placeholder="El. pašto adresas"
          value={user.pastas}
          required=""
        />
        <input
          type="hidden"
          name="p_firstname"
          id="exampleInputName1"
          placeholder="Vardas"
          value={user.asmuo}
          required=""
        />
        <input
          type="hidden"
          name="p_lastname"
          value={user.asmuo}
          id="exampleInputSURName1"
          placeholder="Pavardė"
          required=""
        />
        <input
          type="hidden"
          name="p_phone"
          value={user.numeris}
          id="exampleInputSURName1"
          placeholder="Telefono numeris"
        />
        <input type="hidden" name="lang" value="LIT" />
        <input type="hidden" name="price" value={user.liko_sumoketi} />
        <input type="hidden" name="country" value="LT" />
        <input type="hidden" id="p_address" name="p_address" value={user.id} />
        <input
          type="hidden"
          name="id"
          value={parseInt(getRandomArbitrary(10000000001, 90000000001))}
        />
        {user.liko_sumoketi > 0 ? (
          <>
            <MDButton color="success" variant="gradient" type="submit">
              Apmokėti ({user.liko_sumoketi} EUR)*
            </MDButton>
            <br />
            <br />
          </>
        ) : user.aktyvus ? (
          <Alert severity="success">
            {user.vaikas} narystė apmokėta ir aktyvi. Laukiame jūsų
            treniruotėse.
          </Alert>
        ) : (
          <Alert severity="warning">
            {user.vaikas} narystė sustabdyta. Norint vėl lankyti treniruotes,
            prašome susisiekti su mumis.
          </Alert>
        )}
      </form>
    </MDBox>
  );
}

export default Payment;

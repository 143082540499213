import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Button from "@mui/material/Button";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";

import * as React from "react";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import cookie from "services/cookie.js";

export default function Suvestine() {
  const [nariai, setNariai] = React.useState([]);
  const token = cookie.getCookie("token");
  const currentUser = cookie.getUser("token");

  function sendNotification(data) {
    const id = data.target.id;

    console.info(id);

    if (!id) {
      return;
    }

    fetch("api/naujo-nario-patvirtinimas", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        id: id,
      }),
    })
      .then((res) => res.json())
      .then((data) => alert(data.message))
      .catch((err) => console.info(err));
  }

  React.useEffect(() => {
    fetch(`api/nariai-nauji/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    })
      .then((res) => res.json())
      .then((data) => setNariai(data.results));
  }, []);

  const columns = [
    { Header: "Statusas", accessor: "apmoketa", align: "left" },
    { Header: "Asmuo", accessor: "asmuo", align: "left" },
    { Header: "Moka", accessor: "kaina", align: "left" },
    { Header: "Galioja iki", accessor: "galioja_iki", align: "left" },
    { Header: "Grupė", accessor: "grupe", align: "left" },
  ];

  if (currentUser.level == 9) {
    columns.push({
      Header: "Mok.",
      accessor: "mokejimas",
      align: "left",
      width: "20px",
    });
  }

  let rows = nariai ? JSON.parse(JSON.stringify(nariai)) : [];

  for (let i = 0; i < rows.length; i++) {
    if (currentUser.level == 9) {
      rows[i].mokejimas = (
        <Button
          variant="text"
          id={rows[i].id}
          onClick={(e) => sendNotification(e)}
        >
          Siųsti
        </Button>
      );
    }
    rows[i].asmuo = (
      <MDBox display="flex" alignItems="center" lineHeight={1}>
        <MDBox lineHeight={1}>
          <MDTypography display="block" variant="button" fontWeight="medium">
            ID: {rows[i].id} {rows[i].asmuo}
          </MDTypography>
          <MDTypography variant="caption">{rows[i].pastas}</MDTypography>
          <br />
          {rows[i].numeris ? (
            <MDTypography variant="caption">{rows[i].numeris}</MDTypography>
          ) : null}
        </MDBox>
      </MDBox>
    );

    rows[i].kaina = (
      <>
        {rows[i].kaina} EUR
        <br />
        Kas {rows[i].mok_laikotarpis} mėn.
      </>
    );

    if (rows[i].naujas_narys) {
      rows[i].apmoketa = "Naujas narys";
    } else {
      rows[i].apmoketa = "Įvedė treneris";
    }
  }

  function compare(a, b) {
    if (a.apmoketa < b.apmoketa) {
      return -1;
    }
    if (a.apmoketa > b.apmoketa) {
      return 1;
    }
    return 0;
  }

  rows.sort(compare);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDTypography
                mt={3}
                ml={3}
                ariant="button"
                fontWeight="regular"
                variant="h5"
              >
                Visi nariai:
              </MDTypography>
              <MDBox>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  canSearch
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

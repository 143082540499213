import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";

import * as React from "react";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Button from "@mui/material/Button";

import cookie from "services/cookie.js";

export default function Suvestine() {
  const [nariai, setNariai] = React.useState([]);
  const token = cookie.getCookie("token");
  const currentUser = cookie.getUser("token");

  function sendNotification(data) {
    const id = data.target.id;

    console.info(id);

    if (!id) {
      return;
    }

    fetch("api/paklausimas", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        id: id,
      }),
    })
      .then((res) => res.json())
      .then((data) => alert(data.message))
      .catch((err) => console.info(err));
  }

  function sendNotification2(data) {
    const id = data.target.id;

    console.info(id);

    if (!id) {
      return;
    }

    fetch("api/paklausimas-antras", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        id: id,
      }),
    })
      .then((res) => res.json())
      .then((data) => alert(data.message))
      .catch((err) => console.info(err));
  }

  function sendNotificationBefore(data) {
    const id = data.target.id;

    console.info(id);

    if (!id) {
      return;
    }

    fetch("api/paklausimas-pries", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        id: id,
      }),
    })
      .then((res) => res.json())
      .then((data) => alert(data.message))
      .catch((err) => console.info(err));
  }

  function sendNotificationSlepti(data) {
    const id = data.target.id;

    console.info(id);

    if (!id) {
      return;
    }

    fetch("api/pabandymai-slepti", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        id: id,
      }),
    })
      .then((res) => res.json())
      .then((data) => alert(data.message))
      .catch((err) => console.info(err));
  }

  React.useEffect(() => {
    fetch(`api/pabandymai/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    })
      .then((res) => res.json())
      .then((data) => setNariai(data.results));
  }, []);

  const columns = [
    { Header: "Asmuo", accessor: "asmuo", align: "left" },
    { Header: "El. paštas", accessor: "pastas", align: "left" },
    { Header: "Tel. numeris", accessor: "numeris", align: "left" },
    { Header: "Treniruotės data", accessor: "treniruotes_data", align: "left" },
    { Header: "Treniruotė", accessor: "treniruote", align: "left" },
    { Header: "Grupė", accessor: "grupe", align: "left" },
  ];

  if (currentUser.level == 9) {
    columns.push({ Header: "Treneris", accessor: "treneris", align: "left" });
    columns.push({
      Header: "Įsigijo narystę",
      accessor: "statusas",
      align: "left",
    });
    columns.push({
      Header: "Laiškas prieš",
      accessor: "pries",
      align: "left",
      width: "20px",
    });
    columns.push({
      Header: "Laiškas po (1 diena)",
      accessor: "po",
      align: "left",
      width: "20px",
    });

    columns.push({
      Header: "Laiškas po (2 sav.)",
      accessor: "po2",
      align: "left",
      width: "20px",
    });
    columns.push({
      Header: "Slėpti",
      accessor: "slepti",
      align: "left",
      width: "20px",
    });
  }

  let rows = nariai ? JSON.parse(JSON.stringify(nariai)) : [];

  for (let i = 0; i < rows.length; i++) {
    if (rows[i].treneris == 1) {
      rows[i].treneris = "Gabrielius";
    }

    if (rows[i].treneris == 2) {
      rows[i].treneris = "Domantas";
    }

    if (rows[i].treneris == 3) {
      rows[i].treneris = "Edgaras";
    }

    if (rows[i].treneris == 4) {
      rows[i].treneris = "Karolina";
    }

    if (rows[i].treneris == 5) {
      rows[i].treneris = "Titas";
    }

    if (rows[i].treneris == 6) {
      rows[i].treneris = "Modesta";
    }

    if (rows[i].treneris == 7) {
      rows[i].treneris = "Remigijus";
    }

    if (rows[i].grupe == "I grupė" && rows[i].treneris == "Karolina") {
      rows[i].grupe =
        "6-8 metų grupė (Trenerė KAROLINA) (19:10 val. Pirmadieniais ir trečiadieniais)";
    }

    if (rows[i].grupe == "II grupė" && rows[i].treneris == "Karolina") {
      rows[i].grupe =
        "6-8 metų grupė (Trenerė JULIJA) (19:00 val. Antradieniais ir Ketvirtadieniais)";
    }

    if (rows[i].grupe == "III grupė" && rows[i].treneris == "Karolina") {
      rows[i].grupe =
        "9-14 metų grupė (Trenerė KAROLINA) (18:20 val. Pirmadieniais ir trečiadieniais)";
    }

    if (rows[i].grupe == "IV grupė" && rows[i].treneris == "Karolina") {
      rows[i].grupe =
        "4-6 metų grupė (Trenerė JULIJA) (17:30 val. Antradieniais ir Ketvirtadieniais)";
    }

    if (rows[i].grupe == "V grupė" && rows[i].treneris == "Karolina") {
      rows[i].grupe =
        "4-6 metų grupė (Trenerė RŪTA) (19:00 val. Antradieniais ir Ketvirtadieniais)";
    }

    if (rows[i].grupe == "VI grupė" && rows[i].treneris == "Karolina") {
      rows[i].grupe =
        "4-6 metų grupė (Trenerė JULIJA) (16:30 val. Pirmadieniais ir Ketvirtadieniais)";
    }

    if (rows[i].grupe == "VII grupė" && rows[i].treneris == "Karolina") {
      rows[i].grupe =
        "TĖVAI + VAIKAI (Trenerė JULIJA) (17:30 val. Pirmadieniais)";
    }

    if (rows[i].statusas) {
      rows[i].statusas = "TAIP";
    } else {
      rows[i].statusas = "NE";
    }

    if (
      currentUser.level == 9 &&
      rows[i].statusas == "NE" &&
      !rows[i].laiskas_po
    ) {
      rows[i].po = (
        <Button
          variant="text"
          id={rows[i].id}
          onClick={(e) => sendNotification(e)}
        >
          Siųsti
        </Button>
      );
    }

    if (
      currentUser.level == 9 &&
      rows[i].statusas == "NE" &&
      !rows[i].laiskas_po2
    ) {
      rows[i].po2 = (
        <Button
          variant="text"
          id={rows[i].id}
          onClick={(e) => sendNotification2(e)}
        >
          Siųsti
        </Button>
      );
    }

    if (currentUser.level == 9 && !rows[i].laiskas_pries) {
      rows[i].pries = (
        <Button
          variant="text"
          id={rows[i].id}
          onClick={(e) => sendNotificationBefore(e)}
        >
          Siųsti
        </Button>
      );
    }

    if (currentUser.level == 9) {
      rows[i].slepti = (
        <Button
          variant="text"
          id={rows[i].id}
          onClick={(e) => sendNotificationSlepti(e)}
        >
          Slėpti
        </Button>
      );
    }
  }

  function compare(a, b) {
    if (a.treniruotes_data < b.treniruotes_data) {
      return -1;
    }
    if (a.treniruotes_data > b.treniruotes_data) {
      return 1;
    }
    return 0;
  }

  rows.sort(compare);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDTypography
                mt={3}
                ml={3}
                ariant="button"
                fontWeight="regular"
                variant="h5"
              >
                Užsiregistravę pabandyti:
              </MDTypography>
              <MDBox>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  canSearch
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

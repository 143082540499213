import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import dayjs from "dayjs";
import Button from "@mui/material/Button";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";

import * as React from "react";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import cookie from "services/cookie.js";

export default function Suvestine() {
  const [nariai, setNariai] = React.useState([]);
  const token = cookie.getCookie("token");
  const currentUser = cookie.getUser("token");

  function sendNotification(data) {
    const id = data.target.id;

    fetch("api/priminimas", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        id: id,
      }),
    })
      .then((res) => res.json())
      .then((data) => alert(data.message))
      .catch((err) => console.info(err));

    console.info("event");
  }

  function sendNotificationNebelanko(data) {
    const id = data.target.id;

    fetch("api/priminimas-nebelanko", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        id: id,
      }),
    })
      .then((res) => res.json())
      .then((data) => alert(data.message))
      .catch((err) => console.info(err));

    console.info("event");
  }

  function sendNotificationStabdyti(data) {
    const id = data.target.id;

    fetch("api/stabdyti-naryste", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        id: id,
      }),
    })
      .then((res) => res.json())
      .then((data) => alert(data.message))
      .catch((err) => console.info(err));

    console.info("event");
  }

  function sendNotificationStabdytiAtostogas(data) {
    const id = data.target.id;

    fetch("api/stabdyti-atostogas", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        id: id,
      }),
    })
      .then((res) => res.json())
      .then((data) => alert(data.message))
      .catch((err) => console.info(err));

    console.info("event");
  }

  function sendNotificationPradetiAtostogas(data) {
    const id = data.target.id;

    fetch("api/pradeti-atostogas", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        id: id,
      }),
    })
      .then((res) => res.json())
      .then((data) => alert(data.message))
      .catch((err) => console.info(err));

    console.info("event");
  }

  React.useEffect(() => {
    fetch(`api/nariai/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const rows = data.results;
        for (let i = 0; i < rows.length; i++) {
          if (currentUser.level == 9) {
            rows[i].mokejimas = (
              <>
                <small>{rows[i].priminimo_data}</small>
                <br />
                <Button
                  variant="text"
                  id={rows[i].id}
                  onClick={(e) => sendNotification(e)}
                >
                  Siųsti
                </Button>
              </>
            );
            rows[i].nebelanko = (
              <>
                <small>{rows[i].nebelanko_priminimo_data}</small>
                <br />
                <Button
                  variant="text"
                  id={rows[i].id}
                  onClick={(e) => sendNotificationNebelanko(e)}
                >
                  Siųsti
                </Button>
              </>
            );
            rows[i].neaktyvus = (
              <>
                <Button
                  variant="text"
                  id={rows[i].id}
                  onClick={(e) => sendNotificationStabdyti(e)}
                >
                  Stabdyti
                </Button>
              </>
            );

            if (rows[i].sustabdziusi) {
              rows[i].atostogos = (
                <>
                  <Button
                    variant="text"
                    id={rows[i].id}
                    onClick={(e) => sendNotificationStabdytiAtostogas(e)}
                  >
                    Stabdyti atostogas
                  </Button>
                </>
              );
            } else {
              rows[i].atostogos = (
                <>
                  <Button
                    variant="text"
                    id={rows[i].id}
                    onClick={(e) => sendNotificationPradetiAtostogas(e)}
                  >
                    Pradeti atostogas
                  </Button>
                </>
              );
            }
          }
          rows[i].asmuo = (
            <MDBox display="flex" alignItems="center" lineHeight={1}>
              <MDBox lineHeight={1}>
                <MDTypography
                  display="block"
                  variant="button"
                  fontWeight="medium"
                >
                  ID: {rows[i].id} {rows[i].asmuo}
                </MDTypography>
                <MDTypography variant="caption">{rows[i].pastas}</MDTypography>
                <br />
                {rows[i].numeris ? (
                  <MDTypography variant="caption">
                    {rows[i].numeris}
                  </MDTypography>
                ) : null}
              </MDBox>
            </MDBox>
          );
          rows[i].veiksmai = (
            <>
              <div>
                <input
                  type="radio"
                  id={`sport_${String(rows[i].id)}`}
                  name={String(rows[i].id)}
                  value="1"
                  checked={rows[i].veiksmai == 1}
                  onChange={(e) => onStatusChange(e)}
                />
                <label htmlFor={`sport_${String(rows[i].id)}`}>Sportuoja</label>
              </div>

              <div>
                <input
                  type="radio"
                  id={`nebesportuoja_${String(rows[i].id)}`}
                  name={String(rows[i].id)}
                  value="2"
                  checked={rows[i].veiksmai == 2}
                  onChange={(e) => onStatusChange(e)}
                />
                <label htmlFor={`nebesportuoja_${String(rows[i].id)}`}>
                  Nebesportuoja visam
                </label>
              </div>

              <div>
                <input
                  type="radio"
                  id={`serga_${String(rows[i].id)}`}
                  name={String(rows[i].id)}
                  value="6"
                  checked={rows[i].veiksmai == 6}
                  onChange={(e) => onStatusChange(e)}
                />
                <label htmlFor={`serga_${String(rows[i].id)}`}>
                  Atostogauja / serga
                </label>
              </div>

              <div>
                <input
                  type="radio"
                  id={`nezinau_${String(rows[i].id)}`}
                  name={String(rows[i].id)}
                  value="3"
                  checked={rows[i].veiksmai == 3}
                  onChange={(e) => onStatusChange(e)}
                />
                <label htmlFor={`nezinau_${String(rows[i].id)}`}>Nežinau</label>
              </div>

              <div>
                <input
                  type="radio"
                  id={`neta_${String(rows[i].id)}`}
                  name={String(rows[i].id)}
                  value="4"
                  checked={rows[i].veiksmai == 4}
                  onChange={(e) => onStatusChange(e)}
                />
                <label htmlFor={`neta_${String(rows[i].id)}`}>
                  Ne toje grupėje
                </label>
              </div>
            </>
          );

          rows[i].kaina = (
            <>
              {rows[i].kaina} EUR
              <br />
              Kas {rows[i].mok_laikotarpis} mėn.
            </>
          );

          if (rows[i].sustabdziusi) {
            rows[i].apmoketa = "Atostogos";
            rows[i].dienos = `Atostogauja: ${dayjs(
              rows[i].atostogauja_nuo
            ).diff(new Date(), "days")}`;
          } else if (dayjs(rows[i].galioja_iki) >= dayjs()) {
            rows[i].apmoketa = "Apmokėta";
            rows[i].dienos = `${dayjs(rows[i].galioja_iki).diff(
              new Date(),
              "days"
            )}`;
          } else {
            rows[i].apmoketa = `Neapmokėta`;
            rows[i].dienos = `${dayjs(rows[i].galioja_iki).diff(
              new Date(),
              "days"
            )}`;
          }

          if (rows[i].statusas == 0) {
            rows[i].apmoketa = "0 - Nepatvirtintas Evaldo";
          }
        }

        function compare(a, b) {
          if (a.apmoketa < b.apmoketa) {
            return -1;
          }
          if (a.apmoketa > b.apmoketa) {
            return 1;
          }
          return 0;
        }

        rows.sort(compare);

        setNariai(rows);
      });
  }, []);

  const columns = [
    { Header: "Apmokėta", accessor: "apmoketa", align: "left" },
    { Header: "Dienos", accessor: "dienos", align: "left" },
    { Header: "Asmuo", accessor: "asmuo", align: "left" },
    { Header: "Moka", accessor: "kaina", align: "left" },
    { Header: "Galioja iki", accessor: "galioja_iki", align: "left" },
    { Header: "Grupė", accessor: "grupe", align: "left" },
  ];

  if (currentUser.level == 9) {
    columns.push(
      {
        Header: "Mokėjimas",
        accessor: "mokejimas",
        width: "20px",
        align: "center",
      },
      { Header: "Nebelanko?", accessor: "nebelanko", align: "left" },
      { Header: "Sust. narystę", accessor: "neaktyvus", align: "left" },
      { Header: "Atostogos", accessor: "atostogos", align: "left" },
      { Header: "Statusas", accessor: "veiksmai", align: "left" },
      { Header: "Trenerio komentaras", accessor: "komentaras", align: "left" }
    );
  }

  let rows = nariai;

  function onStatusChange() {
    return;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDTypography
                mt={3}
                ml={3}
                ariant="button"
                fontWeight="regular"
                variant="h5"
              >
                Visi nariai:
              </MDTypography>
              <MDBox>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  canSearch
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
